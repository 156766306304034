import Vue from 'vue'
import Vuex from 'vuex'
import store_modular from '@/store/store_modular/index.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    store_modular
  }
})
